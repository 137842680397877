import React from "react";
import Layout from "../../components/Layout";
import Container from "react-bootstrap/Container";
import { graphql, PageProps } from "gatsby";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageTitle from "../../components/PageTitle";
import FAQBlock, { FAQBlockInterface } from "../../components/FAQBlock";
import {
  ImageInterface,
  LocalizedContextInterface,
  PortableText,
  MultiLineHeadline,
  SanityColorList,
  Seo
} from "../../types/SanityTypes";
import SEO from "../../components/Seo";
import StructuredData from "../../components/StructuredData";
import { LocalisedPageContext } from "../../types/PageTypes";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import "./styles.scss";

type FaqQueryProps = {
  articleFaq: {
    banner: {
      headline: MultiLineHeadline;
      primaryColor: SanityColorList;
      _rawShortIntro: PortableText;
      _rawHeroImage: ImageInterface;
    };
    seo: Seo;
    slug: {
      current: string;
    };
    content: FAQBlockInterface[];
  };
} & LocalizedContextInterface;

type FAQPageContext = LocalisedPageContext;

export const query = graphql`
  query Faq($_id: String, $language: String, $market: String) {
    articleFaq: sanityArticleFaq(_id: { eq: $_id }) {
      banner {
        headline {
          primaryText
          secondaryText
          tertiaryText
        }
        primaryColor {
          value
          title
        }
        _rawShortIntro(resolveReferences: { maxDepth: 8 })
        _rawHeroImage(resolveReferences: { maxDepth: 8 })
      }
      seo {
        metaTitle
        metaDescription
        jsonld
      }
      slug {
        current
      }
      content {
        ... on SanityFrequentlyAskedQuestions {
          heading
          faqItems {
            question
            _rawAnswer
            answer {
              children {
                text
              }
            }
            _key
          }
        }
      }
    }
    ...LocalizedContext
  }
`;

const Faq = (props: PageProps<FaqQueryProps, FAQPageContext>) => {
  const language = props.pageContext.language;
  const { htmlLang } = useSiteMetadata(language);
  const faq = props.data.articleFaq;

  // This is to make sure banner works of internationalisation
  const lineOne =
    faq.banner.headline.primaryText.length <= 5
      ? faq.banner.headline.primaryText
      : faq.banner.headline.primaryText + " ";
  const lineTwo = faq.banner.headline.secondaryText === null ? " " : faq.banner.headline.secondaryText + " ";
  const lineThree = faq.banner.headline.tertiaryText === null ? " " : faq.banner.headline.tertiaryText + " ";

  const renderFaqItems = () => {
    return (faq.content as FAQBlockInterface[]).map(item => {
      return (
        <React.Fragment key={item.heading}>
          <h2>{item.heading}</h2>
          <FAQBlock faqItems={item.faqItems} />
        </React.Fragment>
      );
    });
  };

  return (
    <Layout localizedContext={{ ...props.pageContext, ...props.data }} pageName={faq.seo.metaTitle} pageType={"faq"}>
      <StructuredData
        type={"FAQPage"}
        name={faq.seo.metaTitle}
        description={faq.seo.metaDescription}
        data={faq}
        customData={faq.seo.jsonld}
        language={htmlLang}
        slug={`/${faq.slug.current}/`}
      />
      <SEO
        title={faq.seo.metaTitle}
        description={faq.seo.metaDescription}
        imageUrl={faq.banner._rawHeroImage.asset.url}
        imageAlt={faq.banner._rawHeroImage.alt}
      />
      <div className="page_faqs">
        <Container fluid className="theme_pink inner" data-testid="faq">
          <Row className="header-type-1">
            <Col>
              <PageTitle
                title={[lineOne, lineTwo, lineThree]}
                image={faq.banner._rawHeroImage}
                color={faq.banner.primaryColor.title}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={{ span: 8, offset: 2 }}>{renderFaqItems()}</Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export default Faq;
